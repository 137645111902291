<template>
  <div class="q-pa-lg user_out">
    <div class="userCard">
      <q-card style="height: 100%;">
        <div class="info">
          <div class="top">
            <div class="left">
              <q-avatar :color="userInfo.avatar ? 'white' : 'primary'" style="border: 1px solid rgba(0, 0, 0, .05);" text-color="white" size="100px">
                <q-img spinner-color="primary" spinner-size="40px" :src="userInfo.avatar" v-if="userInfo.avatar" alt="" />
                <div v-else>{{userInfo.username ? userInfo.username[0] : '未'}}</div>
              </q-avatar>
              <div class="i">
                <div class="uname">
                  欢迎进入，{{isLogin ? userInfo.username || '未知用户' : '游客用户'}}
                </div>
                <div class="sign" v-if="isLogin">
                  <div class="a">普通会员</div>
                </div>
                <div v-else>
                  <q-btn color="primary" style="width: 75px;height: 25px;display: flex;align-items: center;justify-content: center;" @click="login">去登录</q-btn>
                </div>
              </div>
            </div>
            <div style="width: 1px;height: 60px;background-color: rgba(0, 0, 0, .1);"></div>
            <div class="right">
              <div>
                <div class="email child"><span>邮箱：</span>{{userInfo.email || '暂无'}}</div>
                <div class="phone child"><span>手机号：</span>{{userInfo.mobile || '暂无'}}</div>
              </div>
              <div style="margin-left: 64px;">
                <div class="email child"><span>账号状态：</span>{{isLogin ? (userInfo.status === 'normal' ? '正常' : '异常') : '未登录'}}</div>
                <div class="phone child"><span>登录时间：</span>{{userInfo.logintime && userInfo.logintime * 1000 ? fmt(userInfo.logintime * 1000, 'yyyy-MM-dd HH:mm:ss') : '暂无'}}</div>
              </div>
              <q-btn class="logout" color="primary" plain outline v-if="isLogin" @click="logout">注销</q-btn>
            </div>
          </div>
          <q-separator />
        </div>
      </q-card>
    </div>
    <div style="width: 16px;"></div>
    <div class="navs">
      <q-card>
        <div style="padding: 16px;font-size: 14px;font-weight: bold;">友情链接</div>
        <q-list bordered>
          <div v-for="(item,index) in navList" :key="index">
            <q-separator v-if="item.br" />
            <q-item clickable v-ripple v-else @click="navTo(item.link)">
              <q-item-section avatar>
                <q-icon color="primary" name="link" />
              </q-item-section>
              <q-item-section>{{item.name}}</q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-card>
      <div style="height: 16px;"></div>
      <q-card style="min-height: 320px;">
        <div style="padding: 16px;font-size: 14px;font-weight: bold;">联系我们</div>
        <q-separator />
        <div class="us">
          <div class="item child">
            <div class="content"><span class="bold">地址：</span>{{us.ads}}</div>
          </div>
          <q-separator />
          <div class="child">
            <div class="bold">办公室</div>
            <div><span>电话</span><span class="val">18999832045</span><span>传真</span><span class="val">213124</span></div>
          </div>
          <div class="child">
            <div class="bold">机关党委</div>
            <div><span>电话</span><span class="val">18999832045</span><span>传真</span><span class="val">213124</span></div>
          </div>
          <div class="child">
            <div class="bold">政策法规处</div>
            <div><span>电话</span><span class="val">18999832045</span><span>传真</span><span class="val">213124</span></div>
          </div>
          <div class="child">
            <div class="bold">知识产权代办处</div>
            <div><span>电话</span><span class="val">18999832045</span><span>传真</span><span class="val">213124</span></div>
          </div>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: '新疆知识产权公共服务平台',
          link: 'https://xjip.info'
        },
        {
          name: '新疆知识产权交易平台',
          link: 'https://jy.xjip.info'
        },
        {
          name: '新疆知识产权网上培训中心',
          link: 'https://edu.xjip.info'
        },
        {
          name: '新疆维吾尔自治区知识产权监测平台',
          link: 'https://bigscreen.xjip.info'
        },
        {
          br: true
        },
        {
          name: '国家知识产权局',
          link: 'https://www.cnipa.gov.cn'
        },
        {
          name: '国家知识产权公共服务网',
          link: 'http://ggfw.cnipa.gov.cn:8010/PatentCMS_Center/'
        },
        {
          br: true
        },
        {
          name: '新疆维吾尔自治区人民政府网',
          link: 'https://www.xinjiang.gov.cn'
        },
        {
          name: '新疆维吾尔自治区市场监督管理局(知识产权局)',
          link: 'http://scjgj.xinjiang.gov.cn'
        }
      ],
      us: {
        ads: '新疆乌鲁木齐市经济开发区（头屯河区）软件园大师大师打瞌睡姥姥家了',
        tels: [
          
        ]
      }
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.isLogin
    },
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    navTo (e) {
      window.open(e, '_blank')
    },
    fmt (time, fmt) {
      if (!time) return ''
        const date = new Date(time)
        const o = {
          'M+': date.getMonth() + 1,
          'd+': date.getDate(),
          'H+': date.getHours(),
          'm+': date.getMinutes(),
          's+': date.getSeconds(),
          'q+': Math.floor((date.getMonth() + 3) / 3),
          S: date.getMilliseconds(),
        }
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
          )
        for (const k in o) {
          if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              // @ts-ignore: Unreachable code error
              RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            )
          }
        }
      return fmt
    },
    logout () {
      this.$store.dispatch('logout')
    },
    login () {
      window.open('https://trade.xjip.info/index.php?s=/index/user/unionlogin.html&backurl=https://r.xjip.info/#/', '_self')
    }
  },
}
</script>

<style lang="scss" scoped>
.user_out {
  display: flex;
  .navs {
    min-width: 350px;
    a {
      color: #303030;
      text-decoration: none;
    }
  }
  .info {
  }
  .userCard {
    flex: 1;
    .info {
      .top {
        padding: 16px;
        display: flex;
        align-items: center;
        .left {
          flex: 1;
          display: flex;
          .i {
            padding: 20px 0;
            margin-left: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .uname {
              font-size: 18px;
            }
            .sign {
              display: flex;
              .a {
                font-size: 12px;
                background-color: rgba(17, 139, 125, .6);
                color: #FFF;
                padding: 2px 4px;
                border-radius: 10px;
              }
            }
          }
        }
        .right {
          position: relative;
          flex: 2;
          padding-left: 16px;
          line-height: 36px;
          display: flex;
          .child {
            display: flex;
            span {
              text-align: right;
            }
          }
          .logout {
            position: absolute;
            right: 0px;
            bottom: -16px;
            width: 80px;
          }
        }
      }
    }
  }
    .us {
      padding: 16px;
      padding-top: 0;
      .item {
        display: flex;
        max-width: 370px;
      }
      // .child:not(:last-child) {
      //   margin-bottom: 16px;
      // }
      .child {
        margin: 16px 0;
        .val {
          margin: 0 16px;
        }
      }
      .bold {
        font-weight: bold;
      }
    }
}
</style>
